<template>
  <section class="faq-contact">
    <b-row class="pt-75 text-center">
      <b-col cols="12">
        <h2>Fale Conosco</h2>
        <b-card-text class="mb-1">
          Você pode nos contatar através do Telefone, WhatsApp ou E-mail
        </b-card-text>
      </b-col>
    </b-row>

    <b-row class="text-center match-height">
      <b-col sm="12" md="4">
      <div class="card py-1">
        <b-link href="tel:6734205600">
            <b-avatar class="mb-1" size="52" variant="light-primary">
              <feather-icon icon="PhoneCallIcon" size="28" class="rounded" />
            </b-avatar>
            <h5 style="line-height:20px" class="m-0 p-0">Telefone<br>(67) 3420-5600</h5>
        </b-link>
      </div>
      </b-col>

      <b-col sm="12" md="4">
      <div class="card py-1">
        <b-link href="https://api.whatsapp.com/send/?phone=556734205600&text&app_absent=0" target="_blank">
            <b-avatar class="mb-1" size="52" variant="light-success">
              <feather-icon icon="MessageCircleIcon" size="28" class="rounded" />
            </b-avatar>
            <h5 style="line-height:20px" class="m-0 p-0">WhatsApp<br>(67) 3420-5600</h5>
        </b-link>
      </div>
      </b-col>

      <b-col sm="12" md="4">
      <div class="card py-1">
        <b-link href="mailto:atendimento@saobento.com.br">
            <b-avatar class="mb-1" size="52" variant="light-danger">
              <feather-icon icon="MailIcon" size="28" class="rounded" />
            </b-avatar>
          <h5 style="line-height:20px" class="m-0 p-0">E-mail<br>atendimento@saobento.com.br</h5>
        </b-link>
      </div>
      </b-col>

    </b-row>

    <b-row class="pt-75 text-center">
      <b-col cols="12">
        <h2>Localização</h2>
        <b-card-text class="mb-1"> Escritório Central Dourados-MS </b-card-text>
      </b-col>
    </b-row>

    <div class="mapouter mb-2">
      <div class="gmap_canvas">
        <iframe
          class="gmap_iframe"
          width="100%"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=1366&amp;height=400&amp;hl=en&amp;q=são bento incorporadora dourados ms&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div>
    </div>

  </section>
</template>

<script>
import { BCardText, BRow, BCol, BLink, BAvatar } from "bootstrap-vue"

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BLink,
    BAvatar,
  },
}
</script>

<style lang="scss">
.mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 400px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 400px;
}

.gmap_iframe {
    height: 400px !important;
}
</style>
